
  import { defineComponent } from "vue";
  import { client } from '@/client';
  import { ElMessage } from "element-plus";
import { BalanceData, BalanceOptions, BalanceType } from "@/shared/types/BalanceData";
  export default defineComponent({
    name: "balance-add",
    components: {},
    data: () => {
      return {
        params: {} as BalanceData,
        roles: BalanceOptions,
        roleSelected:['0'] as any[],
  
        paramsRules: {
        },
      };
    },
    methods: {
      //新增
        async create() {
            let res = await client.callApi("balance/Add",this.params);
            if (res.isSucc) {
                ElMessage({
                    message: "更新成功^_^",
                    type: "success",
                });
                this.$router.go(-1);
            }else{
                ElMessage({
                    message: res.err.message,
                    type: "error",
                });
            }
        },
        submit(formName: string) {
            let refs:any = this.$refs[formName];
            refs.validate((valid: any) => {
                if (valid) {
                this.create();
                } else {
                console.log("error submit!!");
                return false;
                }
            });
        },
    },
  });
  